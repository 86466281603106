import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import componentGenerator from '../utils/componentGenerator';

const PageTemplate = ({ data, location }) => {
  const page = data?.allContentfulTemplatePage?.edges[0]?.node;

  return (
    <Layout>
      <SEO
        title={page?.seo?.seoTitle}
        description={page?.seo?.description}
        image={page?.seo?.openGraphImage?.file?.url}
        noIndex={page?.seo?.noindex}
        noFollow={page?.seo?.nofollow}
        canonicalUrl={page?.seo?.canonicalUrl}
      />
      {componentGenerator(page?.components, location)}
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query ($slug: String!) {
    allContentfulTemplatePage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          ...contentfulTemplatePage
        }
      }
    }
  }
`;
