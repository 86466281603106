import React from 'react';
import shortid from 'shortid';

import AirtableForm from '../components/AirtableForm';
import CardCarousel from '../components/CardCarousel';
import CardDeck from '../components/CardDeck';
import ConversionPanel from '../components/ConversionPanel';
import Hero from '../components/Hero';
import PartnersCardDeck from '../components/PartnersCardDeck/index';
import Quote from '../components/Quote';
import Showcase from '../components/Showcase';
import TestimonialCarousel from '../components/TestimonialCarousel';

const componentGenerator = (components, location) =>
  components?.map(component => {
    switch (component?.__typename) {
      case 'ContentfulComponentAirtableForm':
        return (
          <AirtableForm
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentCardDeck':
        return (
          <CardDeck
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentCardCarousel':
        return (
          <CardCarousel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentConversionPanel':
        return (
          <ConversionPanel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentHero':
        return (
          <Hero
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentPartnersCardDeck':
        return (
          <PartnersCardDeck
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentQuote':
        return (
          <Quote
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentTestimonialCarousel':
        return (
          <TestimonialCarousel
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      case 'ContentfulComponentShowcase':
        return (
          <Showcase
            component={component}
            location={location}
            key={shortid.generate()}
          />
        );
      default:
    }
  });

export default componentGenerator;
